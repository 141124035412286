import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebaseConfig'; // Adjust based on your project structure
import { collection, getDocs, updateDoc, doc, getDoc } from 'firebase/firestore';

import { Layout, Table, Tabs, Input, Button, Modal, Select, Spin, Row, } from 'antd';
import Fuse from 'fuse.js'; // Import Fuse.js for fuzzy searching
import { useNavigate } from 'react-router-dom';
import { signOut } from "firebase/auth";
import './AdminDashboard.css';




const { Header, Content } = Layout;
const { TabPane } = Tabs;
const { Option } = Select;

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [rejectionReason, setRejectionReason] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [searchField, setSearchField] = useState('email'); // 'email' or 'orderId'
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [error, setError] = useState(null); // State to store any error messages
  const navigate = useNavigate();


  const fetchUsers = async () => {
    setLoading(true);
    try {
      const usersCollection = collection(db, 'users');
      const userSnapshot = await getDocs(usersCollection);
      const usersData = userSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUsers(usersData);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setLoading(false);
    }
  };
  
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const currentUser = auth.currentUser;
        if (!currentUser) {
          setError("You are not logged in. Redirecting to login...");
          setTimeout(() => navigate('/'), 2000); // Redirect to login page after 2 seconds
          return;
        }

        // Fetch the current user's document to check if they are an admin
        const userDoc = await getDoc(doc(db, "users", currentUser.uid));
        const userData = userDoc.data();

        if (userData.role !== 'admin') {
          setError("You are not authorized to access the admin dashboard. Redirecting...");
          setTimeout(() => navigate('/'), 2000); // Redirect non-admin users to login page
          return;
        }

        // If admin, fetch the users collection
        const usersCollection = collection(db, 'users');
        const userSnapshot = await getDocs(usersCollection);
        const users = userSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setUsers(users);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [navigate]);

  const openModal = (user) => {
    setSelectedUser(user);
    setRejectionReason(user.rejectionReason || '');
    setIsModalOpen(true);
  };

  const handleSaveReason = async () => {
    if (selectedUser) {
      try {
        const userRef = doc(db, 'users', selectedUser.id);
        await updateDoc(userRef, { rejectionReason });
        setUsers((prev) =>
          prev.map((user) =>
            user.id === selectedUser.id ? { ...user, rejectionReason } : user
          )
        );
        Modal.success({ title: 'Saved', content: 'Rejection reason updated successfully!' });
      } catch (error) {
        Modal.error({ title: 'Error', content: 'Failed to save rejection reason.' });
        console.error('Error updating rejection reason:', error);
      } finally {
        setIsModalOpen(false);
      }
    }
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };


  const fuse = new Fuse(users, {
    keys: [searchField], // Use the selected field for searching
    threshold: 0.3, // Adjust sensitivity (lower means stricter match)
  });

  const filteredUsers = searchQuery
    ? fuse.search(searchQuery).map((result) => result.item)
    : users;

  const handleVariantChange = async (userId, variant) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { variant });
      setUsers((prev) =>
        prev.map((user) =>
          user.id === userId ? { ...user, variant } : user
        )
      );
      Modal.success({
        title: 'Success',
        content: 'Variant updated successfully!',
      });
    } catch (error) {
      console.error('Error updating variant:', error);
      Modal.error({
        title: 'Error',
        content: 'Failed to update variant.',
      });
    }
  };

  const handleStatusChange = async (userId, status) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { status });
      setUsers((prev) =>
        prev.map((user) =>
          user.id === userId ? { ...user, status } : user
        )
      );
      Modal.success({
        title: 'Success',
        content: 'Status updated successfully!',
      });
    } catch (error) {
      console.error('Error updating status:', error);
      Modal.error({
        title: 'Error',
        content: 'Failed to update status.',
      });
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (loading) {
    return <p>Loading...</p>; // Show a loading message while the users are being fetched
  }

  if (error) {
    return <p>{error}</p>; // Display the error message if any
  }

  const handleRefresh = () => {
    fetchUsers();
  };
  
  const handlePaginationChange = (page, pageSize) => {
    setPagination({ current: page, pageSize });
  };

  const renderTabs = (status = null) => {
    const filteredByStatus = status
      ? filteredUsers.filter((user) => user.status === status)
      : filteredUsers;
  
    const columns = [
      {
        title: 'Email',
        dataIndex: 'email',
        key: 'email',
      },
      {
        title: 'Order ID',
        dataIndex: 'orderId',
        key: 'orderId',
      },
      {
        title: 'Variant',
        dataIndex: 'variant',
        key: 'variant',
        render: (variant, record) => (
          <Select
            defaultValue={variant || 'Not Available'}
            style={{ width: 150 }}
            onChange={(value) => handleVariantChange(record.id, value)}
          >
            <Option value="lite">Lite</Option>
            <Option value="pro">Pro</Option>
            <Option value="ultimate">Ultimate</Option>
            <Option value="">Not Available</Option>
          </Select>
        ),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
          <Select
            defaultValue={status}
            style={{ width: 150 }}
            onChange={(value) => handleStatusChange(record.id, value)}
          >
            <Option value="pending">Pending</Option>
            <Option value="approved">Approved</Option>
            <Option value="rejected">Rejected</Option>
          </Select>
        ),
      },
      {
        title: 'Rejection Reason',
        dataIndex: 'rejectionReason',
        key: 'rejectionReason',
        render: (_, record) => (
          record.status === 'rejected' ? (
            <Button type="link" onClick={() => openModal(record)}>
              {record.rejectionReason ? 'View/Edit' : 'Add Reason'}
            </Button>
          ) : (
            '-'
          )
        ),
      },
    ];
  
    return (
      <Table
        dataSource={filteredByStatus}
        columns={columns}
        rowKey="id"
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          showSizeChanger: true,
          pageSizeOptions: ['5', '10', '20', '50'],
          onChange: handlePaginationChange,
          onShowSizeChange: handlePaginationChange,
          position: ['bottomCenter'], // Center-align pagination controls
        }}
      />
    );
  };
  
  return (
    <Layout className="dashboard-layout">
      <Header className="header">
        <div className="logo">Admin Dashboard</div>
        <div className="logout-container">
          <button onClick={handleRefresh} className="logout-button">
              <i className="fas fa-refresh"></i> <span className="nav-text">Refresh</span>
            </button>
            <span className="empty-space"></span>
          <button onClick={handleLogout} className="logout-button">
            <i className="fas fa-sign-out-alt"></i> <span className="nav-text">Logout</span>
          </button>
        </div>
      </Header>
      <Content className="content">
        <Row className="search-row">
          <Select
            defaultValue="email"
            onChange={(value) => setSearchField(value)}
          >
            <Option value="email">Search by Email</Option>
            <Option value="orderId">Search by Order ID</Option>
          </Select>
          <Input
            placeholder={`Search by ${searchField}`}
            onChange={handleSearch}
          />
        </Row>
        {loading ? (
          <Spin size="large" style={{ display: 'block', margin: '50px auto' }} />
        ) : (
          <Tabs defaultActiveKey="1">
            <TabPane tab={`All Users (${filteredUsers.length})`} key="1">
              {renderTabs()}
            </TabPane>
            <TabPane tab={`Pending (${filteredUsers.filter((u) => u.status === 'pending').length})`} key="2">
              {renderTabs('pending')}
            </TabPane>
            <TabPane tab={`Approved (${filteredUsers.filter((u) => u.status === 'approved').length})`} key="3">
              {renderTabs('approved')}
            </TabPane>
            <TabPane tab={`Rejected (${filteredUsers.filter((u) => u.status === 'rejected').length})`} key="4">
              {renderTabs('rejected')}
            </TabPane>
          </Tabs>
        )}
        <Modal
          title="Rejection Reason"
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onOk={handleSaveReason}
        >
          <Input.TextArea
            value={rejectionReason}
            onChange={(e) => setRejectionReason(e.target.value)}
            placeholder="Enter rejection reason"
            rows={4}
          />
        </Modal>
      </Content>
    </Layout>
  );  
};

export default AdminDashboard;
