import React, { useState } from 'react';
import { signInWithEmailAndPassword, setPersistence, browserLocalPersistence, browserSessionPersistence } from "firebase/auth";
import { auth, db } from './firebaseConfig';  // Import the auth and db instances
import { useNavigate } from 'react-router-dom'; // Import for redirection with React Router v6
import { doc, getDoc } from "firebase/firestore"; // Import Firestore methods

const LoginForm = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false); // Track Remember Me checkbox
  const [error, setError] = useState(null);
  const [fullError, setFullError] = useState(null); // Store the full error message
  const [loading, setLoading] = useState(false); // Add a loading state
  const navigate = useNavigate(); // useNavigate hook for redirection in v6

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null); // Reset error state
    setFullError(null); // Reset full error state
    setLoading(true); // Start loading state
  
    try {
      // Set persistence based on Remember Me checkbox
      await setPersistence(auth, rememberMe ? browserLocalPersistence : browserSessionPersistence);
  
      const userCredential = await signInWithEmailAndPassword(auth, login, password);
      const user = userCredential.user;
      console.log('Logged in as:', user.email);
  
      // Fetch the user's document from Firestore
      const userDoc = await getDoc(doc(db, "users", user.uid));
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
  
        // Get Firebase Authentication token after login
        // const idToken = await user.getIdToken();

        // Check the user's role and navigate accordingly
        if (userData.role === 'admin') {
          navigate('/admin-dashboard');
        } else {
          navigate('/approval-status');
        }
      } else {
        console.error("No such document!");
      }
    } catch (err) {
      // Store the full error message
      setFullError(err.message);
  
      // Truncate the error message if it's too long
      const truncatedError = err.message.length > 100
        ? `${err.message.substring(0, 100)}...`
        : err.message;
  
      setError(truncatedError);
      console.error('Error during login:', err);
    } finally {
      setLoading(false); // End loading state
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="login-form">
      <h2>Sign In</h2>
      <div className="form-group">
        <label htmlFor="login">Email</label>
        <input
          type="email"
          id="login"
          value={login}
          onChange={(e) => setLogin(e.target.value)}
          placeholder="Email"
          required
          className="form-input"
        />
      </div>
      <div className="form-group">
        <label htmlFor="password">Password</label>
        <div className="password-container">
          <input
            type={passwordVisible ? "text" : "password"}
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            required
            className="form-input"
          />
          <i
            className={`fa ${passwordVisible ? "fa-eye-slash" : "fa-eye"} toggle-password`}
            onClick={togglePasswordVisibility}
          ></i>
        </div>
      </div>
      <div className="form-footer">
        <div className="remember-me">
          <input 
            type="checkbox" 
            id="rememberMe" 
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)} // Update remember me state
          />
          <label htmlFor="rememberMe">Remember Me</label>
        </div>
        <a href="/forgot-password" className="forgot-password">Forgot Password?</a>
      </div>
      <button type="submit" className="sign-in-button" disabled={loading}>
        {loading ? "Signing In..." : "Sign In"}
      </button>
      {error && (
        <div className="error-message">
          {error}
          {fullError && fullError.length > 100 && (
            <span
              style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline', marginLeft: '5px' }}
              onClick={() => alert(fullError)}
            >
              Read more
            </span>
          )}
        </div>
      )}
    </form>
  );
};

export default LoginForm;
